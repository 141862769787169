// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-agenda-js": () => import("./../../../src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-artistes-js": () => import("./../../../src/pages/artistes.js" /* webpackChunkName: "component---src-pages-artistes-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-artists-details-js": () => import("./../../../src/templates/artistsDetails.js" /* webpackChunkName: "component---src-templates-artists-details-js" */),
  "component---src-templates-focus-details-js": () => import("./../../../src/templates/focusDetails.js" /* webpackChunkName: "component---src-templates-focus-details-js" */)
}

